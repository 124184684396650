export default [
  {
    header: 'دسترسی ها',
  },
  {
    title: 'چت',
    route: 'apps-chats',
    icon: 'MessageSquareIcon',
    resource: 'Support',
    action: 'Support',
  },
  // {
  //   title: 'کانال',
  //   icon: 'SendIcon',
  //   children: [
  //     {
  //       title: 'لیست کانال ها',
  //       route: 'apps-channel-list',
  //       resource: 'Support',
  //       action: 'Support',
  //     },
  //   ],
  // },
  {
    title: 'کاربران',
    icon: 'UserIcon',
    children: [
      {
        title: 'لیست کاربران',
        route: 'apps-users-list',
      },
      {
        title: 'لیست متخلفان',
        route: 'apps-Transgressive-users',
      },
      {
        title: 'لیست تخلفات',
        route: 'apps-Transgressive-list',
      },
    ],
  },
  // {
  //   title: 'مسدود سازی',
  //   icon: 'SlashIcon',
  //   children: [
  //     {
  //       title: 'لیست مسدودشدگان',
  //       route: 'apps-block-list',
  //     },
  //     {
  //       title: 'مسدودسازی کاربران',
  //       route: 'apps-block',
  //     },
  //   ],
  // },
  {
    title: 'دانشجویان',
    route: 'apps-e-commerce-students',
    icon: 'BookIcon',
  },
  {
    title: 'محصولات',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'لیست محصولات',
        route: 'apps-e-commerce-shop',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'لیست دسته بندی های محصولات',
        route: 'apps-e-commerce-categories',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'افزودن محصول',
        route: 'apps-e-commerce-add',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'لیست نظرات محصولات',
        route: 'apps-e-commerce-comments',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
    ],
  },
  {
    title: 'دوره ها',
    icon: 'BookIcon',
    children: [
      {
        title: 'لیست دوره ها',
        route: 'apps-e-commerce-courses',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'افزودن دوره',
        route: 'apps-e-commerce-add-course',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'لیست دسته بندی های دوره ها',
        route: 'apps-e-commerce-courses-categories',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
    ],
  },
  {
    title: 'آزمون',
    icon: 'EditIcon',
    children: [
      {
        title: 'لیست الگوی آزمون‌ها',
        route: 'apps-exam-list',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'ساخت الگوی آزمون',
        route: 'apps-exam-create',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'لیست پرسش ها',
        route: 'apps-exam-question-list',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'افزودن پرسش',
        route: 'apps-exam-question-create',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
    ],
  },
  // {
  //   title: 'سمینار ها',
  //   icon: 'AirplayIcon',
  //   children: [
  //     {
  //       title: 'لیست سمینار ها',
  //       route: 'apps-seminar-list',
  //
  //     },
  //     {
  //       title: 'اضافه کردن سمینار',
  //       route: 'apps-seminar-add',
  //     },
  //     {
  //       title: 'تیزر سمینار',
  //       route: 'apps-seminar-trailer',
  //     },
  //   ],
  // },
  // {
  //   title: 'پست آموزشی',
  //   icon: 'GridIcon',
  //   children: [
  //     {
  //       title: 'لیست پست ها',
  //       route: 'apps-educational-post-list',
  //     },
  //     {
  //       title: 'ساخت پست',
  //       route: 'apps-educational-post-add',
  //     },
  //   ],
  // },
  // {
  //   title: 'لایو',
  //   icon: 'ApertureIcon',
  //   children: [
  //     {
  //       title: 'لیست لایو ها',
  //       route: 'apps-live-list',
  //     },
  //     {
  //       title: 'ساخت لایو',
  //       route: 'apps-live-add',
  //     },
  //   ],
  // },
  {
    title: 'سفارشات',
    icon: 'TrelloIcon',
    children: [
      {
        title: 'لیست سفارشات',
        route: 'apps-orders-list',
        resource: 'Accountent',
        action: 'Accountent',
      },
    ],
  },
  {
    title: 'مالی',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'موجودی ها',
        route: 'apps-financial-wallets-list',
        resource: 'Accountent',
        action: 'Accountent',
      },
      {
        title: 'پرداخت ها',
        route: 'apps-financial-payments-list',
        resource: 'Accountent',
        action: 'Accountent',
      },
    ],
  },
  {
    title: 'کوپن ها',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'لیست کوپن ها',
        route: 'apps-coupons-list',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
      {
        title: 'ساخت کوپن',
        route: 'apps-coupon-add',
        resource: 'ProductManager',
        action: 'ProductManager',
      },
    ],
  },
  {
    title: 'مدیریت پرسنل',
    route: 'apps-admins',
    icon: 'AwardIcon',
  },
  // {
  //   title: 'استوری',
  //   icon: 'CommandIcon',
  //   children: [
  //     {
  //       title: 'لیست استوری ها',
  //       route: 'apps-post-list',
  //     },
  //     {
  //       title: 'ساخت استوری',
  //       route: 'apps-post-add',
  //     },
  //   ],
  // },
  // {
  //   title: 'استوری ها',
  //   icon: 'InstagramIcon',
  //   children: [
  //     {
  //       title: 'لیست استوری ها',
  //       route: 'apps-stories-list',
  //     },
  //     {
  //       title: 'ساخت استوری',
  //       route: 'apps-stories-add',
  //     },
  //   ],
  // },
  {
    title: 'بلاگ',
    icon: 'BookOpenIcon',
    // resource: 'Blogger',
    // action: 'Blogger',
    children: [
      {
        title: 'لیست بلاگ‌ها و اطلاعیه‌ها',
        route: 'pages-blog-list',
        resource: 'Blogger',
        action: 'Blogger',
      },
      {
        title: 'افزودن بلاگ',
        route: 'pages-blog-create',
        resource: 'Blogger',
        action: 'Blogger',
      },
      {
        title: 'دسته بندی های بلاگ',
        route: 'pages-blog-categories-list',
        resource: 'Blogger',
        action: 'Blogger',
      },
      {
        title: 'لیست نظرات بلاگ',
        route: 'pages-blog-comments',
        resource: 'Blogger',
        action: 'Blogger',
      },
    ],
  },
  {
    title: 'تغییرات صفحات',
    icon: 'SettingsIcon',
    children: [
      // {
      //   title: 'مانترا روزانه',
      //   route: 'apps-daily-mantra',
      // },
      // {
      //   title: 'مانترا صفحه اصلی',
      //   route: 'apps-home-mantra',
      // },
      {
        title: 'صفحه اصلی',
        route: 'apps-home-settings',
      },
      // {
      //   title: 'درباره ما',
      //   route: 'apps-aboutUs',
      // },
      // {
      //   title: 'تماس با ما',
      //   route: 'apps-contactUs',
      // },
    ],
  },
  {
    title: 'مشاوره',
    icon: 'ClipboardIcon',
    children: [
      {
        title:'لیست درخواست‌ها',
        route: 'apps-consultant-numbers-list',
      },
    ]
  },
  {
    title: 'دوره حضوری',
    icon: 'CodesandboxIcon',
    children: [
      {
        title:'تغییر صفحه',
        route: 'apps-present-banner-edit',
      },
      {
        title:'لیست درخواست‌ها',
        route: 'apps-present-seminar-list',
      },
    ]
  },
  // {
  //   title: 'شانسی',
  //   icon: 'ZapIcon',
  //   children: [
  //     {
  //       title:'لیست جوایز',
  //       route: 'apps-fortune',
  //     },
  //     {
  //       title:'لیست آیتم های مانترا',
  //       route: 'apps-mantra-list',
  //     },
  //     {
  //       title:'افزودن آیتم مانترا',
  //       route: 'apps-mantra-add',
  //     }
  //   ]
  // },
]
